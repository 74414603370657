import { useQuery } from '@apollo/client';
import React, { useEffect, useMemo, useState } from 'react';
import MuyitongxueLogo from '@Assets/muyitongxue-logo--new.png';

import { GetExhibitionByIdDocument } from '../../services/graphql/types/graphql';
import { useParams } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import { CloseCircleOutlined } from '@ant-design/icons';
import { Loader } from '../../components/Loader';

import { Autoplay, Navigation } from 'swiper/modules';
import { useStoreContext } from '../../factorys/useStoreContext';
import { IdentityContext } from '../App/identity.context';
import { AuthingContext } from '../App/authing.context';
import { cloneDeep } from 'lodash';
import { format } from 'date-fns';

export const SemesterExhibitionPage = () => {
  const { user } = useStoreContext(AuthingContext);
  // const userId = 'd974bddc-7f30-415f-ac23-211da7f11cd6';
  const userId = user?.externalId;

  const { id: exhibitionId } = useParams();
  if (!exhibitionId) {
    throw new Error('exhibitionId is required');
  }

  const { loading, data, error, updateQuery } = useQuery(GetExhibitionByIdDocument, {
    variables: {
      exhibitionId,
    },
  });

  const [currentSection, setCurrentSection] = useState<number | null>(null);
  // useEffect(() => console.info('currentSection', currentSection), [currentSection]);
  const displayExhibitionItems = useMemo(() => {
    if (currentSection === null) {
      return [];
    }

    if (currentSection === -1) {
      return data?.exhibition?.sections.flatMap((section) => section.items) ?? [];
    }

    return cloneDeep(data?.exhibition?.sections[currentSection].items);
  }, [currentSection]);

  const ownerIndicator = useMemo(() => {
    const ownerIndicator: Record<string, boolean> = {};
    for (const section of data?.exhibition?.sections ?? []) {
      if (section.items.some((item) => item.submission.authors.some((a) => a.userId === userId))) {
        ownerIndicator[section.sectionId] = true;
      } else {
        ownerIndicator[section.sectionId] = false;
      }
    }

    return ownerIndicator;
  }, [data]);

  useEffect(() => console.info('data', data), [data]);

  if (loading || error || !data?.exhibition) {
    return <Loader fullscreen />;
  }

  return (
    <div className="bg-[#797979] text-white h-screen w-screen overflow-hidden semester-exhibition-page__container">
      <div className="overflow-auto items-center h-full w-full justify-center flex flex-col">
        <div className="flex flex-col justify-center items-center ">
          <img className="w-[144px] h-[144px]" src={MuyitongxueLogo} alt="" />
          <div className="mt-11 text-xl tracking-[2.4px] text-center">
            {/* {data.exhibition.period}
            <br></br> */}
            {data.exhibition.series}
          </div>
          <div className="mt-2 text-[32px] tracking-[3.2px]">{data.exhibition.name}</div>
          {data.exhibition.date && (
            <div
              className="mt-1 text-[20px] font-medium tracking-[2px]"
              onClick={() => setCurrentSection(-1)}
            >
              {format(data.exhibition.date, 'yyyy.M.dd')}
            </div>
          )}
          <div
            className="mt-[22px] leading-6 tracking-[1.4px] w-[1000px]"
            dangerouslySetInnerHTML={{ __html: data.exhibition.description }}
          ></div>
        </div>
        <div className="flex space-x-[38px] w-full mt-12 justify-center items-center">
          {data.exhibition?.sections.map((section, index) => {
            return (
              <div
                key={`section-${section.sectionId}`}
                className="relative"
                onClick={() => setCurrentSection(index)}
              >
                <img
                  className="w-[136px] h-[136px]"
                  src={`https://coca-public.oss-cn-beijing.aliyuncs.com/exhibitions/${exhibitionId}/${section.sectionId}/cover.png?123`}
                  alt=""
                />
                <div className="absolute top-0 left-0 w-full h-full bg-[#4f4f4f] opacity-50"></div>
                <div className="absolute top-0 left-0 text-white text-[24px] leading-none tracking-[2.4px] flex items-center w-full">
                  {/* <div>{section.name}</div> */}
                  {ownerIndicator[section.sectionId] && (
                    <div className=" rounded-full bg-red-600 w-4 h-4 ml-auto mr-2"></div>
                  )}
                </div>
              </div>
            );
          })}
        </div>
      </div>
      {currentSection !== null && displayExhibitionItems && (
        <div className="absolute top-0 left-0 w-screen h-screen bg-black z-[100]">
          <div className="absolute flex justify-center items-center w-6 h-6 z-[100] top-[5px] right-[5px] leading-6 text-2xl text-[#7d7d7d] transition-opacity">
            <CloseCircleOutlined onClick={() => setCurrentSection(null)} />
          </div>
          <Swiper
            modules={[Navigation, Autoplay]}
            navigation={true}
            spaceBetween={0}
            slidesPerView={1}
            autoplay={currentSection === -1 ? { delay: 2000 } : false}
            // onSlideChange={() => console.log('slide change')}
            // onSwiper={(swiper) => console.log(swiper)}
          >
            {displayExhibitionItems
              .sort((a, b) => (a.submission.authors.some((aa) => aa.userId === userId) ? -1 : 1))
              .map((item, index) => {
                return (
                  <SwiperSlide key={`exhibition-item-${item.submission.submissionId}`}>
                    <div className="flex flex-col items-center justify-center w-full h-full">
                      <div className="w-[90vw] h-[70vh] flex items-center justify-between overflow-hidden">
                        {Array(item.number)
                          .fill(0)
                          .map((_, i) => {
                            return (
                              <img
                                className="object-contain flex-1"
                                key={`exhibition-item-${item.submission.submissionId}-${i}`}
                                src={`https://coca-public.oss-cn-beijing.aliyuncs.com/exhibitions/${exhibitionId}/${item?.sectionId}/${item.submission.submissionId}-${i}.jpg`}
                                alt=""
                              />
                            );
                          })}
                      </div>
                      <div className="w-[90vw] mt-5 flex justify-center items-center flex-col text-lg">
                        <div>
                          {(
                            item.submission.assignment.name.split('：')[1] ??
                            item.submission.assignment.name.split('：')[0]
                          )
                            .replace('《', '')
                            .replace('》', '')}
                        </div>
                        <div>{item.submission.authors[0].name}</div>
                        <div className="mt-[10px] text-[10px] w-[200px] leading-[1.5]">
                          {item.submission.credits.map((c) => c.rubricLevel?.text).join('，')}，
                          {item.submission.extraComment}
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                );
              })}
          </Swiper>
        </div>
      )}
    </div>
  );
};
