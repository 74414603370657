import React, { useRef } from 'react';
import { gql, useApolloClient, useMutation } from '@apollo/client';

import AddButtonSVG from './assets/add-button.svg?react';
import Spinner from '../../assets/spinner.svg?react';
import {
  GetStudentCoursePageDataDocument,
  HomePageSelfSubmissionFragmentDoc,
  SubmitAssignmentSimpleDocument,
} from '../../services/graphql/types/graphql';
import { getFragmentData } from '../../services/graphql/types';
import { toast } from 'react-toastify';
import ImageBlobReducer from 'image-blob-reduce';

const ArtworkUpload = ({
  assignmentId,
  refetchQueries = ['GetHomePageData'],
  onFinish,
}: {
  assignmentId: string;
  refetchQueries?: string[];
  onFinish?: () => void;
}) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const [submitAssignmentSimple, { data, loading, error }] = useMutation(
    SubmitAssignmentSimpleDocument,
    {
      refetchQueries,
      awaitRefetchQueries: true,
      // update: (cache, { data }) => {
      //   if (!data) {
      //     return;
      //   }
      //   cache.modify({
      //     id: cache.identify(data.submitAssignmentSimple.artworks[0]),
      //     fields: {
      //       medias() {
      //         return data.submitAssignmentSimple.artworks[0].medias;
      //       }
      //     },
      //   });
      // },
    },
  );

  const onChange: React.ChangeEventHandler<HTMLInputElement> = async (e) => {
    const {
      target: { validity, files, value },
    } = e;
    if (!value) {
      return;
    }

    const imageFile = files?.[0];
    if (!imageFile) {
      console.error('No image file selected.');
      toast.error('请选择图片');
      return false;
    }

    console.info(
      `Select image: ${imageFile.name}. Size: ${imageFile.size} Bytes. Type: ${imageFile.type}`,
    );

    const imageReducer = new ImageBlobReducer();

    let resizedImage: Blob;
    try {
      resizedImage = await imageReducer.toBlob(imageFile, { max: 3000 });
    } catch (error) {
      toast.error('不支持的图片格式');
      return false;
    }

    console.info(`Resize image size: ${resizedImage.size} Bytes. Type: ${resizedImage.type}`);

    const { data } = await submitAssignmentSimple({
      variables: { file: { file: resizedImage, assignmentId } },
    });
    const submission = data?.submitAssignmentSimple;

    const medias =
      submission?.artworks[0]?.medias.toSorted((a, b) => (a.priority ?? 0) - (b.priority ?? 0)) ??
      [];
    console.info(
      `Submission ${submission?.submissionId} artwork media uploaded. MediaId: ${
        medias[medias.length - 1]?.mediaId
      }. Url ${medias[medias.length - 1]?.url}`,
    );

    e.target.value = '';
  };

  return (
    <div className="overflow-hidden bg-[#bcbcbc] h-full">
      {loading ? (
        <div className="loading-icon">
          <Spinner className="scale-50"></Spinner>
        </div>
      ) : (
        <div className="text-[150%] w-full h-full flex items-center justify-center relative z-[100]">
          <div className="w-[36px] h-[36px] cursor-pointer">
            <AddButtonSVG onClick={() => inputRef?.current?.click()} />
          </div>
        </div>
      )}
      <input
        ref={inputRef}
        className="absolute opacity-0 w-0 h-0 top-0 left-0"
        type="file"
        accept="image/*;capture=camera"
        required
        onChange={onChange}
        aria-label="upload"
      />
    </div>
  );
};

export default ArtworkUpload;
