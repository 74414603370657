import { FC } from 'react';

import styles from './index.module.scss';

export interface IWithCameraCheckerProps {
  children: React.ReactNode;
  hasCameraAccess: boolean;
}

const WithCameraChecker: FC<IWithCameraCheckerProps> = ({ children, hasCameraAccess }) => {
  return hasCameraAccess ? (
    children
  ) : (
    <div className={styles.cameraCheckerTips}>
      <div>
        尚未开启浏览器麦克风和摄像头权限，点击授权或检查浏览器设置手动开启麦克风和摄像头权限。这通常可以在浏览器的“设置”或“偏好设置”
        - “隐私和安全” - “网站权限”中找到，然后找到“麦克风和摄像头”权限设置。
      </div>
      <div>
        在Windows系统中，可以进入设置 - 隐私 - 应用程序权限，确保麦克风和摄像头的权限已经开启
      </div>
      <div>
        在MacOS系统中，可以进入系统偏好设置 - 安全性与隐私 -
        应用程序权限，在左侧的列表中，找到并勾选“麦克风”和“摄像头”选项
      </div>
    </div>
  );
};

export default WithCameraChecker;
