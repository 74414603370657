import { isBefore, format } from 'date-fns';
import { useState, useContext, useMemo, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import ArtworkUpload from '../../../../components/ArtworkUpload';
import { ossImage } from '../../../../utils/ossImage';
import { AuthingContext } from '../../../App/authing.context';
import { ArtworkItem } from '../ArtworkItem';
import cx from 'classnames';
import qs from 'query-string';
import {
  HomePageArtworkMediaFragmentDoc,
  HomePageLessonAssignmentFragmentDoc,
  HomePageSelfSubmissionFragmentDoc,
  SubmissionStatus,
  UpdateSubmissionStatusDocument,
} from '../../../../services/graphql/types/graphql';
import { FragmentType, getFragmentData } from '../../../../services/graphql/types';
import { IdentityContext } from '../../../App/identity.context';
import { useStoreContext } from '../../../../factorys/useStoreContext';
import { PortalModal } from '../../../../components/PortalModal';
import { Transcript } from '../../../Transcript';
import { useMutation } from '@apollo/client';
import { toast } from 'react-toastify';

export const AssignmentItem = ({
  classId,
  courseId,
  data,
  dragging,
}: {
  data: FragmentType<typeof HomePageLessonAssignmentFragmentDoc>;
  classId: string;
  courseId: string;
  dragging: boolean;
}): JSX.Element => {
  const { user } = useContext(AuthingContext);
  const [lightBoxArtworkIndex, setLightBoxArtworkIndex] = useState<number | undefined>(undefined);
  const [submissionTranscript, setSubmissionTrascript] = useState('');
  const [canSubmit, setCanSubmit] = useState(true);

  const navigate = useNavigate();

  const [updateSubmissionStatus] = useMutation(UpdateSubmissionStatusDocument);

  const { currentIdentity } = useStoreContext(IdentityContext);
  const role = currentIdentity?.identity;

  const assignment = getFragmentData(HomePageLessonAssignmentFragmentDoc, data);
  const selfSubmission = getFragmentData(
    HomePageSelfSubmissionFragmentDoc,
    assignment.selfSubmission,
  );
  const submissionArtworkMedias =
    getFragmentData(
      HomePageArtworkMediaFragmentDoc,
      selfSubmission?.artworks[0]?.medias ?? [],
    ).toSorted((a, b) => (a.priority ?? 0) - (b.priority ?? 0)) ?? [];

  const isEnabled = useMemo(() => {
    const isCurrentOpen = isBefore(new Date(assignment.openDate as string), new Date());
    const isCurrentDue = isBefore(new Date(assignment.dueDate as string), new Date());

    // console.info('isCurrentOpen', assignment.openDate, isCurrentOpen);
    // console.info('isCurrentDue', assignment.dueDate, isCurrentDue);

    const shouldShowRed = isCurrentOpen && !isCurrentDue;

    // console.info(assignment.name, shouldShowRed);

    return shouldShowRed;
  }, [assignment]);

  const [mouseDownTime, setMouseDownTime] = useState<number | undefined>(undefined);

  const onMouseDown = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    setMouseDownTime(e.timeStamp);
  };

  const onMouseUp = (index: number) => (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    // console.info('mouse up');
    if (mouseDownTime && e.timeStamp - mouseDownTime < 100) {
      setMouseDownTime(undefined);
      if (role === 'teacher') {
        const query = qs.stringify({ assignmentId: assignment.assignmentId, courseId, classId });
        navigate(`/eval?${query}`);
      } else {
        if (selfSubmission?.submissionStatus === SubmissionStatus.Completed) {
          setSubmissionTrascript(selfSubmission?.submissionId ?? '');
        }
      }
    }
  };

  const handleSubmitAssignment = () => {
    console.info(`try submit assignment ${selfSubmission?.submissionId}`);

    const action = async () => {
      if (!selfSubmission) {
        return;
      }

      setCanSubmit(false);
      await new Promise((resolve) => setTimeout(resolve, 2000));
      await updateSubmissionStatus({
        variables: {
          submissionId: selfSubmission.submissionId,
          submissionStatus: SubmissionStatus.Submitted,
        },
      });
    };

    toast.promise(action(), {
      pending: '正在提交',
      success: '提交成功',
      error: '提交失败',
    });
  };

  const deleteAble = useMemo(() => {
    if (!selfSubmission) {
      return false;
    }

    // return (
    //   role === 'student' &&
    //   isEnabled &&
    //   [SubmissionStatus.Unsubmitted, SubmissionStatus.Retracted].includes(
    //     selfSubmission.submissionStatus,
    //   )
    // );

    return true;
  }, [role, isEnabled, selfSubmission]);

  // useEffect(
  //   () => console.info('submissionArtworkMedias', submissionArtworkMedias),
  //   [submissionArtworkMedias],
  // );

  if (!role || !user) {
    return <div></div>;
  }

  return (
    <div className="flex flex-col relative ml-[52px] flex-[auto_0_0]">
      <div className="assignment-item__artworks">
        <div
          className={cx('main-artwork relative', { isDue: !isEnabled })}
          onMouseDown={onMouseDown}
          onMouseUp={onMouseUp(0)}
        >
          {['teacher'].includes(role) || submissionArtworkMedias[0] ? (
            <>
              <ArtworkItem
                deleteAble={deleteAble}
                assignmentId={assignment.assignmentId}
                data={submissionArtworkMedias[0]}
              />
              <div className="absolute top-0 left-0 w-full h-full bg-black opacity-50 "></div>
            </>
          ) : (
            isEnabled &&
            (!selfSubmission || !submissionArtworkMedias[0]) && (
              <ArtworkUpload assignmentId={assignment.assignmentId}></ArtworkUpload>
            )
          )}

          {assignment.minQuantity !== 0 && (
            <div
              className={cx('absolute top-0 left-0 flex flex-col px-5 py-6 w-full h-full reletive')}
            >
              <div className="border-b border-white flex justify-between pb-3">
                <div className="flex items-center">
                  <div className="">
                    完成<br></br>日期
                  </div>
                  <span className="text-[32px] ml-3">
                    {format(new Date(assignment.dueDate as string), 'M.d')}
                  </span>
                </div>
                <div className="flex items-center">
                  <div>
                    最低<br></br>数量
                  </div>
                  <span className="text-[32px] ml-3">{assignment.minQuantity}</span>
                </div>
              </div>
              <div className="mt-2 flex w-full h-full">
                {selfSubmission?.submissionStatus &&
                ['Completed', 'CompletedButNoGrade'].includes(selfSubmission?.submissionStatus) ? (
                  <Transcript info submissionId={selfSubmission.submissionId}></Transcript>
                ) : (
                  <div className="description">{assignment.description}</div>
                )}
              </div>
            </div>
          )}

          {/* {selfSubmission && submissionArtworkMedias[0] && (
            <div className="absolute bottom-5 right-5 text-black">
              {[SubmissionStatus.Unsubmitted, SubmissionStatus.Retracted].includes(
                selfSubmission?.submissionStatus,
              ) && (
                <button
                  type="button"
                  className="w-12 h-12 rounded-full bg-[#f0403c] flex justify-center items-center disabled:bg-[#c5c5c5]"
                  onClick={handleSubmitAssignment}
                  disabled={!canSubmit}
                >
                  {selfSubmission?.submissionStatus === SubmissionStatus.Unsubmitted ? (
                    '提 交'
                  ) : (
                    <>
                      重新<br></br>提交
                    </>
                  )}
                </button>
              )}

              {[SubmissionStatus.Submitted, SubmissionStatus.TaApproved].includes(
                selfSubmission.submissionStatus,
              ) && (
                <button
                  type="button"
                  className="w-12 h-12 rounded-full bg-[#e5e7eb] flex justify-center items-center"
                  disabled={true}
                >
                  审阅中
                </button>
              )}
            </div>
          )} */}
        </div>

        {role === 'student' &&
          ((isEnabled && submissionArtworkMedias.length > 0) ||
            (!isEnabled && submissionArtworkMedias.length > 1)) && (
            <div className="ml-3 grid grid-flow-col grid-rows-2 gap-3 ">
              {submissionArtworkMedias.slice(1).map((media, artworkIndex) => {
                return (
                  <div key={media.mediaId} className="object-contain w-[114px] h-[114px]">
                    <ArtworkItem
                      deleteAble={deleteAble}
                      assignmentId={assignment.assignmentId}
                      data={media}
                      onMouseDown={onMouseDown}
                      onMouseUp={onMouseUp(artworkIndex + 1)}
                    />
                  </div>
                );
              })}
              {selfSubmission && isEnabled && (
                // [SubmissionStatus.Unsubmitted, SubmissionStatus.Retracted].includes(
                //   selfSubmission.submissionStatus,
                // ) &&
                <div key="upload-artwork" className="w-[114px] h-[114px] bg-[#bcbcbc]">
                  <ArtworkUpload assignmentId={assignment.assignmentId}></ArtworkUpload>
                </div>
              )}
            </div>
          )}

        {/* 成绩单 */}
        {submissionTranscript &&
          selfSubmission?.submissionStatus === SubmissionStatus.Completed && (
            <PortalModal>
              <div className="z-[9999] absolute w-full h-full top-0 left-0">
                <div
                  onClick={() => setSubmissionTrascript('')}
                  className="absolute w-full h-full top-0 left-0 bg-black opacity-80"
                ></div>
                <div className="absolute top-0 left-1/2 -translate-x-1/2">
                  <Transcript submissionId={submissionTranscript}></Transcript>
                </div>
              </div>
            </PortalModal>
          )}
      </div>
      <div className="mt-2">
        <div className="text-[#E7380D] text-xs">{assignment.name.split('：')?.[0]}</div>
        <div className="mt-1 text-base">
          {assignment.name.split('：')?.[1]?.replace(/《(.*)》/, '$1')}
        </div>
      </div>
    </div>
  );
};
