import Select, { Option } from 'rc-select';
import React, { useContext, useEffect, useMemo } from 'react';
import { IdentityContext } from '../../containers/App/identity.context';
import { useStoreContext } from '../../factorys/useStoreContext';
import { Loader } from '../Loader';
import cx from 'classnames';

const IdentitySelector: React.FC<{
  children?: React.ReactNode;
  enableSchool?: boolean;
  enableClass?: boolean;
  enableCourse?: boolean;
  schoolFilter?: (schoolId: string) => boolean;
  classFilter?: (classId: string) => boolean;
}> = ({
  children,
  enableSchool = false,
  enableClass = false,
  enableCourse = false,
  schoolFilter = () => true,
  classFilter = () => true,
}) => {
  const {
    currentClassId,
    currentClass,
    setCurrentClassId,
    availableClasses,

    currentSchoolId,
    currentSchool,
    setCurrentSchoolId,
    availableSchools,

    currentCourseId,
    currentCourse,
    setCurrentCourseId,
    availableCourses,

    userIdentities,
  } = useStoreContext(IdentityContext);

  // useEffect(() => {
  //   console.info(availableClasses);
  // }, [availableClasses]);

  useEffect(() => {
    if (!availableClasses?.map((c) => c.classId).includes(currentClassId)) {
      setCurrentClassId(availableClasses?.[0]?.classId);
      console.info(`Reset currentClassId to ${availableClasses?.[0]?.classId}`);
    }
  }, [currentClassId, availableClasses]);

  const handleSchoolChange = (e: any) => {
    let schoolId: string;
    if (e?.target) {
      ({ value: schoolId } = e.target);
    } else {
      schoolId = e;
    }

    const classId = availableSchools?.find((s) => s.schoolId === schoolId)?.classes[0].classId;
    console.info('SchoolChanged');
    console.info('schoolId', schoolId);
    console.info('classId', classId);

    setCurrentSchoolId(schoolId);
    setCurrentClassId(classId);
  };

  const handleClassChange = (e: any) => {
    let value: string;
    if (e?.target) {
      ({ value } = e.target);
    } else {
      value = e;
    }

    setCurrentClassId(value);
    console.info('ClassChanged');
    console.info('classId', value);
  };

  const handleCourseChange = (e: any) => {
    let value: string;
    if (e?.target) {
      ({ value } = e.target);
    } else {
      value = e;
    }

    setCurrentCourseId(value);
    console.info('CourseChanged');
    console.info('courseId', value);
  };

  if (!userIdentities || !currentSchool || !currentClass) {
    return (
      <div>
        <Loader fullscreen></Loader>
      </div>
    );
  }

  return (
    <div className="school-class-selector flex justify-between text-lg">
      {enableSchool && (
        <Select
          className="text-[18px]"
          style={{ fontSize: '18px' }}
          value={currentSchool.name}
          onChange={handleSchoolChange}
          dropdownMatchSelectWidth={200}
          disabled={availableSchools?.length === 1}
          // showArrow={availableSchools?.length !== 1}
          dropdownClassName="z-[10000]"
        >
          {availableSchools
            ?.filter((s) => schoolFilter(s.schoolId))
            .map((s) => {
              return (
                <Option
                  className={cx({ 'bg-slate-500': s.name === currentSchool.name })}
                  key={s.schoolId}
                >
                  {s.name}
                </Option>
              );
            })}
        </Select>
      )}
      {enableClass && (
        <Select
          className=""
          style={{ fontSize: '18px', whiteSpace: 'nowrap' }}
          value={currentClass.name}
          onChange={handleClassChange}
          dropdownMatchSelectWidth={200}
          disabled={availableClasses?.length === 1}
          // showArrow={availableClasses?.length !== 1}
          dropdownClassName="z-[10000]"
        >
          {availableClasses
            ?.filter((c) => classFilter(c.classId))
            .map((c, i) => {
              return (
                <Option
                  className={cx({ 'bg-slate-500': c.name === currentClass.name })}
                  key={c.classId}
                >
                  {c.name}
                </Option>
              );
            })}
        </Select>
      )}
      {enableCourse && (
        <Select
          className=""
          style={{ fontSize: '18px', whiteSpace: 'nowrap' }}
          value={currentCourse?.course.name.replace('_', ' ')}
          onChange={handleCourseChange}
          dropdownMatchSelectWidth={200}
          disabled={availableCourses?.length === 1}
          // showArrow={availableClasses?.length !== 1}
          dropdownClassName="z-[10000]"
        >
          {availableCourses?.map((c, i) => {
            return <Option key={c.course.courseId}>{c.course.name.replace('_', ' ')}</Option>;
          })}
        </Select>
      )}
      {children}
    </div>
  );
};

export default IdentitySelector;
