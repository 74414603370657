import { useEffect, useRef, useState } from "react";

export interface IUseVideoControllerProps {
  id: string;
}

const useVideoController = ({ id }: IUseVideoControllerProps) => {
  const [targetVideoElement, setTargetVideoElement] =
    useState<HTMLVideoElement | null>(null);

  /** 控制面板定时器 */
  const cancelControllerTimerRef = useRef<number | null>(null);

  /** 是否展示控制面板 */
  const [showController, setShowController] = useState(true);

  /** 控制播放暂停 */
  const [isPlaying, setIsPlaying] = useState(false);

  /** 当前播放秒数 */
  const [currentPlayTime, setCurrentPlayTime] = useState(0);

  /** 视频总时长 */
  const totalDuration = useRef(0);

  /** 是否已经播放结束 */
  const [isEnded, setIsEnded] = useState(false);

  /** 是否在全屏状态 */
  const [isFullScreen, setIsFullScreen] = useState(false);

  const handlePlayOrPause = () => {
    if (isEnded) {
      return;
    }
    if (targetVideoElement) {
      if (isPlaying) {
        targetVideoElement.pause();
      } else {
        targetVideoElement.play();
      }
      setIsPlaying(!isPlaying);
    }
  };

  const handleFullScreenChange = () => {
    if (isFullScreen) {
      document?.exitFullscreen();
    } else {
      targetVideoElement?.parentElement?.requestFullscreen();
    }
  };

  useEffect(() => {
    const handleMouseMoveInVideo = () => {
      setShowController(true);
      if (cancelControllerTimerRef.current) {
        clearTimeout(cancelControllerTimerRef.current);
      }
      cancelControllerTimerRef.current = window.setTimeout(() => {
        setShowController(false);
      }, 5000);
    };

    // 确保didMount后才能获取到videoElement
    setTimeout(() => {
      const videoElement = document.getElementById(id) as HTMLVideoElement;
      setTargetVideoElement(videoElement);
      /** 获取视频总时长 */
      totalDuration.current = videoElement.duration;
      /** 监听是否播放结束 */
      videoElement.addEventListener("ended", () => {
        setIsEnded(true);
      });

      videoElement?.parentElement?.addEventListener(
        "mousemove",
        handleMouseMoveInVideo
      );
    }, 500);

    document.addEventListener("fullscreenchange", () => {
      if (document.fullscreenElement) {
        setIsFullScreen(true);
      } else {
        setIsFullScreen(false);
      }
    });

    return () =>
      targetVideoElement?.parentElement?.removeEventListener(
        "mousemove",
        handleMouseMoveInVideo
      );
  }, [id]);

  /** 监听播放时间变化 */
  useEffect(() => {
    if (targetVideoElement) {
      targetVideoElement.addEventListener("timeupdate", () => {
        setCurrentPlayTime(targetVideoElement.currentTime);
      });
    }
  }, [targetVideoElement]);


  const getFormattedTime = (seconds: number) => {
    const minutes = Math.floor(seconds / 60);
    const leftSeconds = Math.floor(seconds % 60);
    return `${minutes.toString().padStart(2, "0")}:${leftSeconds
      .toString()
      .padStart(2, "0")}`;
  };

  return {
    isEnded,
    isPlaying,
    handlePlayOrPause,
    currentPlayTimeString: getFormattedTime(currentPlayTime),
    currentPlayTime,
    totalDuration: getFormattedTime(totalDuration.current),
    isFullScreen,
    handleFullScreenChange,
    showController,
  };
};

export default useVideoController;
