import React, {
  createContext,
  ReducerState,
  useContext,
  useEffect,
  useMemo,
  useReducer,
  useState,
} from 'react';
import { AuthClientProvider, Guard, GuardMode, User } from '@authing/react18-ui-components';
import { AuthenticationClient, SocialConnectionProvider } from 'authing-js-sdk';
import { Routes, Route } from 'react-router-dom';
import { Loader } from '../../components/Loader';

const AUTHING_APP_ID = '61f55658e1745690afbe2604';
const authClient = new AuthenticationClient({
  // 替换你的 AppId
  appId: AUTHING_APP_ID,
});

export const AuthingContext = createContext({
  user: undefined as undefined | null | User,
  role: undefined as undefined | string,
  authClient: null as AuthenticationClient | null,
  logout: undefined as undefined | (() => Promise<void>),
});

export const AuthingContextProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [user, setUser] = useState<User | null | undefined>(undefined);
  const [role, setRole] = useState<string | undefined>(undefined);

  useEffect(() => {
    if (!authClient) {
      console.warn('authClient not initialized');
      return;
    }

    authClient
      .checkLoginStatus()
      .then(() => authClient.getCurrentUser())
      .then(async (user) => {
        setUser(user);
        if (user) {
          const isTeacher = await authClient.hasRole('teacher');
          setRole(isTeacher ? 'teacher' : 'student');
        }

        console.info(`logged in as ${user?.name ?? user?.nickname} (${user?.externalId})`);
      })
      .catch((e) => console.error(e));
  }, []);

  const logout = async () => {
    const yn = confirm('是否确认登出？');
    if (yn) {
      await authClient?.logout();
      localStorage.removeItem('CurrentSchoolId');
      localStorage.removeItem('CurrentClassId');

      location.assign(location.origin);
    }
  };

  const onLogin = (user: User) => {
    void (async () => {
      if (user.token && authClient) {
        authClient?.setToken(user.token);
        const u = await authClient.getCurrentUser();
        setUser(u);
        const isTeacher = await authClient.hasRole('teacher');
        setRole(isTeacher ? 'teacher' : 'student');
      }
    })();
  };

  if (user === undefined) {
    return <Loader fullscreen />;
  }

  if (user === null) {
    return (
      <div
        style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
        }}
      >
        <AuthClientProvider client={authClient}>
          <Guard
            appId={AUTHING_APP_ID}
            onLogin={onLogin}
            config={{
              socialConnections: [
                SocialConnectionProvider.WECHATPC,
                SocialConnectionProvider.WECHATMOBILE,
                SocialConnectionProvider.WECHATMP,
              ],
            }}
          />
        </AuthClientProvider>
      </div>
    );
  }

  return (
    <AuthingContext.Provider value={{ user, role, authClient, logout }}>
      {children}
    </AuthingContext.Provider>
  );
};
