import { createUploadLink } from 'apollo-upload-client';
import { ApolloClient, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { loadErrorMessages, loadDevMessages } from '@apollo/client/dev';

if (import.meta.env.NODE_ENV !== 'production') {
  // Adds messages only in a dev environment
  loadDevMessages();
  loadErrorMessages();
}

const httpLink = createUploadLink({
  uri: '/graphql',
});

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = localStorage.getItem('_authing_token');
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  };
});

const GraphQLClient = new ApolloClient({
  ssrMode: typeof window === 'undefined',
  // connectToDevTools: import.meta.env.NODE_ENV !== 'production',
  // defaultOptions: {
  //   watchQuery: {
  //     fetchPolicy: 'no-cache',
  //     errorPolicy: 'all',
  //   },
  //   query: {
  //     fetchPolicy: 'no-cache',
  //     errorPolicy: 'all',
  //   },
  // },
  cache: new InMemoryCache({
    typePolicies: {
      Submission: {
        keyFields: ['submissionId'],
      },
      Artwork: {
        keyFields: ['artworkId'],
        fields: {
          medias: {
            merge(existing = [], incoming) {
              // console.log('merge Artwork medias', existing, incoming);
              return incoming;
            },
          },
        },
      },
      Course: {
        keyFields: ['courseId'],
      },
      Assignment: {
        keyFields: ['assignmentId'],
      },
      Exhibition: {
        keyFields: ['exhibitionId'],
      },
      Lesson: {
        keyFields: ['lessonId'],
      },
      Media: {
        keyFields: ['mediaId'],
      },
      User: {
        keyFields: ['userId'],
      },
      Rubric: {
        keyFields: ['rubricId'],
      },
      RubricLevel: {
        keyFields: ['rubricLevelId'],
      },
    },
  }),
  link: authLink.concat(httpLink),
});

export default GraphQLClient;
