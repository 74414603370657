import { FC, useEffect } from "react";
import { Watermark } from "watermark-js-plus";

export interface IWithWatermarkProps {
  content: string;
  targetElementId: string;
  children: React.ReactNode;
}

const WithWatermark: FC<IWithWatermarkProps> = ({
  content,
  targetElementId,
  children,
}: IWithWatermarkProps) => {
  useEffect(() => {
    const watermark = new Watermark({
      content,
      width: 200,
      height: 200,
      parent:
        document.getElementById(targetElementId)?.parentElement ?? undefined,
    });
    watermark.create();
  }, []);

  return children;
};

export default WithWatermark;
