import { FC, useEffect } from 'react';

import styles from './index.module.scss';
import {
  CaretRightOutlined,
  FullscreenExitOutlined,
  FullscreenOutlined,
  PauseOutlined,
} from '@ant-design/icons';
import useVideoController from './useVideoController';
import classNames from 'classnames';

export interface IVideoPlayerProps {
  id: string;
  videoProps?: React.VideoHTMLAttributes<HTMLVideoElement>;
  videoSourceProps?: React.SourceHTMLAttributes<HTMLSourceElement>;
  onCurrentPlayTimeChange?: (seconds: number) => void;
  initPlayTime?: number;
}

const VideoPlayer: FC<IVideoPlayerProps> = ({
  id,
  videoProps = {},
  videoSourceProps = {},
  onCurrentPlayTimeChange,
  initPlayTime,
}) => {
  const {
    isEnded,
    showController,
    isPlaying,
    handlePlayOrPause,
    currentPlayTime,
    currentPlayTimeString,
    totalDuration,
    isFullScreen,
    handleFullScreenChange,
  } = useVideoController({
    id,
  });

  const renderPlayPauseIcon = () => {
    if (isEnded) {
      return <span className={styles.isEndedText}>播放已结束</span>;
    }
    return isPlaying ? (
      <PauseOutlined
        className={classNames(styles.playIcon, styles.icon)}
        onClick={handlePlayOrPause}
      />
    ) : (
      <CaretRightOutlined
        className={classNames(styles.playIcon, styles.icon)}
        onClick={handlePlayOrPause}
      />
    );
  };

  const renderFullScreenIcon = () => {
    return isFullScreen ? (
      <FullscreenExitOutlined className={styles.icon} onClick={handleFullScreenChange} />
    ) : (
      <FullscreenOutlined className={styles.icon} onClick={handleFullScreenChange} />
    );
  };

  const renderControllerBar = () => {
    return (
      <div className={styles.controllerBar}>
        <div className={styles.leftButtons}>
          {renderPlayPauseIcon()}
          <div className={styles.time}>
            <span>{currentPlayTimeString}</span>/<span>{totalDuration}</span>
          </div>
        </div>
        <div>{renderFullScreenIcon()}</div>
      </div>
    );
  };

  useEffect(() => {
    onCurrentPlayTimeChange?.(currentPlayTime);
  }, [onCurrentPlayTimeChange, currentPlayTime]);

  useEffect(() => {
    if (initPlayTime) {
      const video = document.getElementById(id) as HTMLVideoElement;
      video.currentTime = initPlayTime;
    }
  }, []);

  return (
    <div className={styles.videoPlayerContainer}>
      <video
        className={styles.videoTag}
        id={id}
        controls={false}
        width={'100%'}
        onClick={handlePlayOrPause}
        onDoubleClick={handleFullScreenChange}
        loop={false}
        {...videoProps}
      >
        <source {...videoSourceProps} />
      </video>
      {showController && renderControllerBar()}
    </div>
  );
};

export default VideoPlayer;
