import React, { useEffect, useMemo, useState } from 'react';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';

import { HomePage } from '../HomePage';
import TeacherEvaluationPage from '../TeacherEvaluationPage';
import AssignmentUploadPage from '../AssignmentUploadPage';

import ClassroomPage from '../ClassroomPage';
import { GradeBookPage } from '../GradeBookPage';
import { useStoreContext } from '../../factorys/useStoreContext';
import { IdentityContext } from './identity.context';
import { PersonalCourseConcludePage } from '../PersonalCourseConcludePage';
import { SemesterExhibitionPage } from '../SemesterExhibitionPage';
import { StudentCoursePage } from '@Containers/StudentCoursePage';
// import { LandingPage } from '../LandingPage';

const RootRouter = () => {
  const navigate = useNavigate();
  const { currentIdentity } = useStoreContext(IdentityContext);

  return (
    <Routes>
      <Route path="/" element={<HomePage />} />
      <Route path="/course" element={<StudentCoursePage />} />
      <Route path="/semester-conclude" element={<PersonalCourseConcludePage />} />
      <Route path="/exhibition/:id" element={<SemesterExhibitionPage />} />
      <Route path="/eval" element={<TeacherEvaluationPage />} />
      <Route path="/upload/:assignmentId" element={<AssignmentUploadPage />} />
      <Route path="/classroom" element={<ClassroomPage />} />
      <Route path="/gradebook" element={<GradeBookPage />} />
      {/* <Route path="/landing/:id" element={<LandingPage />} /> */}
    </Routes>
  );
};

export default RootRouter;
